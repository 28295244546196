import React from 'react';
import CustomLayout from './src/components/CustomLayout/CustomLayout';

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <CustomLayout>{element}</CustomLayout>;
};

export const wrapRootElement = ({ element }) => <>{element}</>;
